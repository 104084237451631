/* Always show scrollbar */
body {
  overflow: scroll; /* Show scrollbars */
}

/* Fix flickering google sign in button, but it appears after delay */
#GSigninBtn > div > div:first-child{
    display: none;
  }

.event-date {
  border-bottom: 1px solid hsla(200,8%,79%,.5);
    text-align: left;
    font-weight: bold;
    padding-bottom: 2px;
    padding-top: 5px;
    color: #4a4a4a;
    font-size: 14px;
    line-height: 1.42857143;
}

a:-webkit-any-link {
  text-decoration: none;
}